var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { align: "left", justify: "center" } },
    [
      _c("Loading", {
        attrs: { active: _vm.loadingInicial, "is-full-page": true }
      }),
      _vm.mostrarConfirmacao
        ? _c("ConfirmacaoModal", {
            attrs: {
              showDialog: _vm.mostrarConfirmacao,
              tituloModal: _vm.tituloModal,
              textoModal: _vm.textoModal,
              btnConfirmarEmit: "confirmarUpload",
              btnConfirmar: "Ok"
            },
            on: {
              confirmarUpload: function($event) {
                return _vm.closeDialog(true)
              },
              fecharModal: function($event) {
                return _vm.closeDialog(true)
              }
            }
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "pa-3", attrs: { align: "center", justify: "center" } },
        [
          _vm.mostrarBotaoFlutuante && _vm.selecionados.length !== 0
            ? _c(
                "v-tooltip",
                {
                  attrs: { top: "" },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function({ on }) {
                          return [
                            _c(
                              "v-btn",
                              _vm._g(
                                {
                                  attrs: {
                                    fab: "",
                                    dark: "",
                                    color: "green",
                                    fixed: "",
                                    right: "",
                                    bottom: ""
                                  },
                                  on: { click: _vm.dialogTemCerteza }
                                },
                                on
                              ),
                              [
                                _c("v-icon", { attrs: { dark: "" } }, [
                                  _vm._v("check")
                                ])
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    false,
                    350095688
                  )
                },
                [_c("span", [_vm._v("Enviar Arquivos")])]
              )
            : _vm._e(),
          _c(
            "v-card",
            { attrs: { align: "left", justify: "left" } },
            [
              _c(
                "v-card-text",
                { staticClass: "pa-3 font-weight-light white black--text" },
                [
                  _c(
                    "v-icon",
                    { staticClass: "primary--text lighten-2 pa-2" },
                    [_vm._v(" mdi-sync ")]
                  ),
                  _vm._v("Títulos > Upload de Arquivos de Protesto em Massa ")
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card",
            {
              staticClass: "mt-2 mb-3",
              nativeOn: {
                keyup: function($event) {
                  if (
                    !$event.type.indexOf("key") &&
                    _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                  )
                    return null
                  return _vm.botaoFiltrar.apply(null, arguments)
                }
              }
            },
            [
              _c(
                "v-row",
                { staticClass: "mx-6 mt-3 pt-3", attrs: { align: "center" } },
                [
                  _c(
                    "v-col",
                    {
                      attrs: {
                        cols: "12",
                        xs: "12",
                        sm: "12",
                        md: "12",
                        lg: "8"
                      }
                    },
                    [
                      _c(
                        "v-card-text",
                        {
                          staticClass:
                            "text-left font-weight-black display-1 pl-0",
                          style: { color: _vm.primaryColorFont }
                        },
                        [
                          _c(
                            "v-icon",
                            { staticClass: "ma-2", attrs: { large: "" } },
                            [_vm._v(" mdi-progress-close ")]
                          ),
                          _vm._v("Upload de Arquivos de Protesto em lote ")
                        ],
                        1
                      ),
                      _c("v-textarea", {
                        attrs: {
                          solo: "",
                          small: "",
                          label:
                            "Cole aqui os títulos que deseja anexar o arquivo de protesto pendente",
                          hint:
                            "Insira nesse campo todos os títulos que deseja buscar separando por virgula, por exemplo: 477977/1, 721603/6, 755202/5",
                          placeholder: "Exemplo:  477977/1, 721603/6, 755202/5",
                          clearable: ""
                        },
                        model: {
                          value: _vm.filtros.ds_nosso_numero,
                          callback: function($$v) {
                            _vm.$set(_vm.filtros, "ds_nosso_numero", $$v)
                          },
                          expression: "filtros.ds_nosso_numero"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    {
                      staticClass: "mt-2",
                      attrs: { cols: "12", sm: "12", md: "12", lg: "4" }
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12", sm: "12", md: "12", lg: "12" } },
                        [
                          _c(
                            "v-card",
                            { staticClass: "grey white--text white--text" },
                            [
                              _c(
                                "v-icon",
                                { staticClass: "ma-2 text-left white--text" },
                                [_vm._v(" mdi-chevron-down ")]
                              ),
                              _vm._v("fazer upload de arquivo XLS ")
                            ],
                            1
                          ),
                          _c(
                            "v-card-text",
                            {
                              staticClass: "font-weight-light text-left title-1"
                            },
                            [
                              _vm._v(
                                " Utilize o campo abaixo para fazer upload de arquivo com os títulos em massa para anexação do arquivo de protesto. Formatos compatíveis: .xls/.xlsx/.xlsm Limite de tamanho: 60mb "
                              )
                            ]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "grey white--text",
                              attrs: {
                                block: "",
                                plain: "",
                                small: "",
                                href: _vm.link_modelo
                              }
                            },
                            [
                              _c("v-icon", { staticClass: "ma-2 text-left" }, [
                                _vm._v(" mdi-file-download ")
                              ]),
                              _vm._v("Baixar modelo ")
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        {
                          staticClass: "mt-2",
                          attrs: { cols: "12", sm: "12" }
                        },
                        [
                          _c("v-file-input", {
                            ref: "myFileInput",
                            staticClass: "mb-n7 white--text",
                            attrs: {
                              accept:
                                "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .xls, .xlsx, .xlsm",
                              label: "Selecione o arquivo (.xls/.xlsx/.xlsm)",
                              "show-size": "",
                              chips: "",
                              solo: "",
                              id: "fileInput",
                              name: "file"
                            },
                            on: { change: _vm.uploadFileReference }
                          }),
                          _vm.fazendoUpload
                            ? _c(
                                "v-card-text",
                                {
                                  staticClass:
                                    "font-weight-light text-left title-1"
                                },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ma-1",
                                      attrs: {
                                        color: "green",
                                        "x-small": "",
                                        elevation: "0",
                                        fab: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { staticClass: "white--text" },
                                        [_vm._v("mdi-check")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(
                                    " Títulos verificados: " +
                                      _vm._s(_vm.retornaDadosXls()) +
                                      " linhas"
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.fazendoUpload
                            ? _c(
                                "v-card-text",
                                { staticClass: "text-left title-1" },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      staticClass: "ma-1",
                                      attrs: {
                                        color: "green",
                                        "x-small": "",
                                        elevation: "0",
                                        fab: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        { staticClass: "white--text" },
                                        [_vm._v("mdi-magnify")]
                                      )
                                    ],
                                    1
                                  ),
                                  _vm._v(" Para confirmar, clique em Filtrar ")
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-expansion-panels",
                        [
                          _c(
                            "v-expansion-panel",
                            [
                              _c(
                                "v-expansion-panel-header",
                                {
                                  staticClass: "grey lighten-4",
                                  attrs: { small: "" }
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    {
                                      staticClass:
                                        "font-weight-light black--text"
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "primary--text lighten-2"
                                        },
                                        [_vm._v(" search ")]
                                      ),
                                      _vm._v(" Filtrar Resultados ")
                                    ],
                                    1
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-expansion-panel-content",
                                [
                                  _c(
                                    "v-row",
                                    { staticClass: "my-3" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "4",
                                            xs: "2",
                                            sm: "2",
                                            md: "3",
                                            lg: "3"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              solo: "",
                                              small: "",
                                              label: "CPF/CNPJ",
                                              hint:
                                                "Informe o Número do CPF/CNPJ",
                                              placeholder: "Ex.:000.000.000-00",
                                              clearable: ""
                                            },
                                            model: {
                                              value:
                                                _vm.filtros
                                                  .nu_identificacao_devedor,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filtros,
                                                  "nu_identificacao_devedor",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "\n                                                filtros.nu_identificacao_devedor\n                                            "
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "5",
                                            xs: "2",
                                            sm: "2",
                                            md: "3",
                                            lg: "3"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              solo: "",
                                              small: "",
                                              label: "Nome",
                                              hint:
                                                "Informe o nome para efetuar a busca",
                                              placeholder: "Ex.: José",
                                              clearable: ""
                                            },
                                            model: {
                                              value: _vm.filtros.nm_devedor,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filtros,
                                                  "nm_devedor",
                                                  $$v
                                                )
                                              },
                                              expression: "filtros.nm_devedor"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "10",
                                            xs: "2",
                                            sm: "2",
                                            md: "3",
                                            lg: "3"
                                          }
                                        },
                                        [
                                          _c("v-select", {
                                            attrs: {
                                              solo: "",
                                              small: "",
                                              items: _vm.listaUfs,
                                              label: "UF",
                                              "return-object": true
                                            },
                                            model: {
                                              value: _vm.filtros.cd_uf_devedor,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  _vm.filtros,
                                                  "cd_uf_devedor",
                                                  $$v
                                                )
                                              },
                                              expression:
                                                "filtros.cd_uf_devedor"
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "4",
                                            xs: "2",
                                            sm: "2",
                                            md: "3",
                                            lg: "3"
                                          }
                                        },
                                        [
                                          _c("v-checkbox", {
                                            staticClass:
                                              "ml-6 mt-3 d-flex align-center justify-center",
                                            attrs: {
                                              color: "primary",
                                              label: "Renegociação"
                                            },
                                            model: {
                                              value: _vm.oferta,
                                              callback: function($$v) {
                                                _vm.oferta = $$v
                                              },
                                              expression: "oferta"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-row",
                                    { staticClass: "my-3" },
                                    [
                                      !_vm.isUsuarioAgrupador
                                        ? _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "4",
                                                xs: "2",
                                                sm: "2",
                                                md: "3",
                                                lg: "3"
                                              }
                                            },
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  solo: "",
                                                  dense: "",
                                                  disabled:
                                                    _vm.loadingAgrupador ||
                                                    _vm.usuarioAgrupador !=
                                                      null,
                                                  loading: _vm.loadingAgrupador,
                                                  items: _vm.agrupadores,
                                                  label: "Agrupador",
                                                  "item-text": "nm_agrupador",
                                                  "item-value": "ds_agrupador",
                                                  "data-cy": "agrupador"
                                                },
                                                model: {
                                                  value:
                                                    _vm.filtros.ds_agrupador,
                                                  callback: function($$v) {
                                                    _vm.$set(
                                                      _vm.filtros,
                                                      "ds_agrupador",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "filtros.ds_agrupador"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-row",
                { staticClass: "mx-0 mt-2 mb-0 pa-0" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    {
                      staticClass: "pr-9",
                      attrs: {
                        cols: "10",
                        xs: "6",
                        sm: "6",
                        md: "3",
                        lg: "3",
                        align: "center"
                      }
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "d-flex justify-center",
                          attrs: {
                            block: "",
                            color: "primary",
                            loading: _vm.loadingFiltros
                          },
                          on: { click: _vm.botaoFiltrar }
                        },
                        [
                          _vm._v(" Filtrar "),
                          _c("v-icon", { staticClass: "ml-2" }, [
                            _vm._v("search")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.temCerteza
            ? _c(
                "v-dialog",
                {
                  attrs: {
                    "max-width": "1000px",
                    persistent: "",
                    scrollable: "",
                    color: "background",
                    transition: "dialog-bottom-transition"
                  },
                  model: {
                    value: _vm.temCerteza,
                    callback: function($$v) {
                      _vm.temCerteza = $$v
                    },
                    expression: "temCerteza"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-toolbar",
                        { attrs: { flat: "", dark: "", color: "primary" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { icon: "", dark: "" },
                              on: { click: _vm.closeDialog }
                            },
                            [_c("v-icon", [_vm._v("mdi-close")])],
                            1
                          ),
                          _vm.titulosEnviadosCheck
                            ? _c("v-toolbar-title", [
                                _vm._v(" Upload de Arquivos de Protesto ")
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _vm.titulosEnviadosCheck
                        ? _c(
                            "v-card-text",
                            {
                              staticClass: "py-5",
                              staticStyle: { "border-radius": "0" },
                              attrs: { "min-height": "200px" }
                            },
                            [
                              _c(
                                "v-list",
                                { staticClass: "pa-0", attrs: { shaped: "" } },
                                [
                                  _c(
                                    "v-subheader",
                                    {
                                      staticClass:
                                        "primary--text title font-weight-black"
                                    },
                                    [
                                      _vm._v(
                                        "Número de Títulos selecionados: " +
                                          _vm._s(_vm.titulosSelecionadosQtd()) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("div", { staticClass: "px-4 my-3" }, [
                                    _vm._v(
                                      " Os Títulos abaixo foram selecionados para anexação de arquivo de protesto. O arquivo correspondente a cada título deve estar "
                                    ),
                                    _c("b", [
                                      _vm._v(
                                        "nomeado com o mesmo número do título"
                                      )
                                    ]),
                                    _vm._v(
                                      " para ser considerado válido. Além disso, somente arquivos no "
                                    ),
                                    _c("b", [_vm._v("formato PDF")]),
                                    _vm._v(" são permitidos. ")
                                  ]),
                                  _c(
                                    "v-row",
                                    { staticClass: "ma-0" },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "font-weight-bold",
                                          attrs: {
                                            cols: "4",
                                            xs: "4",
                                            sm: "4",
                                            md: "4",
                                            lg: "4"
                                          }
                                        },
                                        [_vm._v(" Título ")]
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "font-weight-bold",
                                          attrs: {
                                            cols: "6",
                                            xs: "6",
                                            sm: "6",
                                            md: "6",
                                            lg: "6"
                                          }
                                        },
                                        [_vm._v(" Arquivo(s) anexado(s) ")]
                                      ),
                                      _c("v-col", {
                                        staticClass: "font-weight-bold",
                                        attrs: {
                                          cols: "2",
                                          xs: "2",
                                          sm: "2",
                                          md: "2",
                                          lg: "2"
                                        }
                                      })
                                    ],
                                    1
                                  ),
                                  _vm._l(_vm.listaTitulosAnexo, function(
                                    titulo,
                                    i
                                  ) {
                                    return _c(
                                      "v-list-item",
                                      {
                                        key: i,
                                        staticClass: "px-0",
                                        attrs: { shaped: "" }
                                      },
                                      [
                                        _c(
                                          "v-row",
                                          { staticClass: "ma-0" },
                                          [
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pa-0 d-flex justify-center align-center",
                                                attrs: {
                                                  cols: "4",
                                                  xs: "4",
                                                  sm: "4",
                                                  md: "4",
                                                  lg: "4"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-card-text",
                                                  { staticClass: "bold py-3" },
                                                  [
                                                    _c(
                                                      "v-icon",
                                                      { staticClass: "ma-1" },
                                                      [
                                                        _vm._v(
                                                          "mdi-information"
                                                        )
                                                      ]
                                                    ),
                                                    _vm._v(
                                                      "Título: " +
                                                        _vm._s(
                                                          titulo.ds_nosso_numero
                                                        ) +
                                                        " "
                                                    )
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pa-0 d-flex justify-center align-center",
                                                attrs: {
                                                  cols: "6",
                                                  xs: "6",
                                                  sm: "6",
                                                  md: "6",
                                                  lg: "6"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-card-text",
                                                  { staticClass: "bold py-3" },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.formatarNomesArquivos(
                                                            titulo
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                    titulo.arquivos_selecionados &&
                                                    titulo.arquivos_selecionados
                                                      .length
                                                      ? _c(
                                                          "v-tooltip",
                                                          {
                                                            attrs: { top: "" },
                                                            scopedSlots: _vm._u(
                                                              [
                                                                {
                                                                  key:
                                                                    "activator",
                                                                  fn: function({
                                                                    on,
                                                                    attrs
                                                                  }) {
                                                                    return [
                                                                      _c(
                                                                        "v-icon",
                                                                        _vm._g(
                                                                          _vm._b(
                                                                            {
                                                                              staticClass:
                                                                                "ma-3",
                                                                              attrs: {
                                                                                color: _vm.tamanhoArquivosValido(
                                                                                  titulo.arquivos_selecionados
                                                                                )
                                                                                  ? "green"
                                                                                  : "red"
                                                                              }
                                                                            },
                                                                            "v-icon",
                                                                            attrs,
                                                                            false
                                                                          ),
                                                                          on
                                                                        ),
                                                                        [
                                                                          _vm._v(
                                                                            " " +
                                                                              _vm._s(
                                                                                _vm.tamanhoArquivosValido(
                                                                                  titulo.arquivos_selecionados
                                                                                )
                                                                                  ? "done"
                                                                                  : "error"
                                                                              ) +
                                                                              " "
                                                                          )
                                                                        ]
                                                                      )
                                                                    ]
                                                                  }
                                                                }
                                                              ],
                                                              null,
                                                              true
                                                            )
                                                          },
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.tamanhoArquivosValido(
                                                                      titulo.arquivos_selecionados
                                                                    )
                                                                      ? "Os arquivos selecionados estão dentro do tamanho máximo permitido de 5MB por Título"
                                                                      : "A soma do tamanho dos arquivos não pode ultrapassar 5MB por Título"
                                                                  ) +
                                                                  " "
                                                              )
                                                            ])
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "v-col",
                                              {
                                                staticClass:
                                                  "pa-0 d-flex justify-center align-center",
                                                attrs: {
                                                  cols: "2",
                                                  xs: "2",
                                                  sm: "2",
                                                  md: "2",
                                                  lg: "2"
                                                }
                                              },
                                              [
                                                _c(
                                                  "v-card-text",
                                                  {
                                                    staticClass:
                                                      "py-3 d-flex justify-center align-center"
                                                  },
                                                  [
                                                    _c(
                                                      "v-btn",
                                                      {
                                                        on: {
                                                          click: function(
                                                            $event
                                                          ) {
                                                            _vm.document
                                                              .getElementById(
                                                                `pdfFileInput${i}`
                                                              )
                                                              .click()
                                                          }
                                                        }
                                                      },
                                                      [_vm._v("Selecionar")]
                                                    ),
                                                    _c("v-file-input", {
                                                      attrs: {
                                                        accept:
                                                          "application/pdf, application/zip",
                                                        "hide-input": "",
                                                        "prepend-icon": "",
                                                        multiple: "",
                                                        id: `pdfFileInput${i}`
                                                      },
                                                      on: {
                                                        change: function(
                                                          $event
                                                        ) {
                                                          return _vm.$forceUpdate()
                                                        }
                                                      },
                                                      model: {
                                                        value:
                                                          titulo.arquivos_selecionados,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            titulo,
                                                            "arquivos_selecionados",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "\n                                                titulo.arquivos_selecionados\n                                            "
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  })
                                ],
                                2
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-divider"),
                      _vm.titulosEnviadosCheck
                        ? _c(
                            "v-card-actions",
                            { staticClass: "justify-end" },
                            [
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-2",
                                  attrs: {
                                    color: "success",
                                    loading: _vm.loadingFiltros,
                                    disabled: _vm.verificarPreenchimentoTitulos(
                                      _vm.listaTitulosAnexo
                                    )
                                  },
                                  on: { click: _vm.uploadArquivosMassa }
                                },
                                [
                                  _c("v-icon", [_vm._v(" mdi-chevron-right")]),
                                  _vm._v(" Enviar")
                                ],
                                1
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "ma-2",
                                  attrs: { loading: _vm.loadingFiltros },
                                  on: { click: _vm.closeDialog }
                                },
                                [_vm._v("Cancelar")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm.snackbarDialog
            ? _c("Snackbar", {
                attrs: {
                  mostrarSnackbar: _vm.snackbarDialog,
                  corSnackbar: _vm.snackbarColor,
                  mensagemSnackbar: _vm.mensagemRetornoSnackbar
                },
                on: {
                  fecharSnackbar: function($event) {
                    _vm.snackbarDialog = false
                  }
                }
              })
            : _vm._e(),
          _c("v-data-table", {
            staticClass: "elevation-1 pa-3",
            attrs: {
              headers: _vm.headers,
              items: _vm.titulos,
              "item-text": "titulo",
              options: _vm.options,
              loading: _vm.loading,
              "single-select": _vm.singleSelect,
              "item-key": "id_titulo",
              "show-select": "",
              "footer-props": {
                itemsPerPageOptions: [50, 1000, 5000, -1],
                itemsPerPageText: "Itens por página:"
              },
              "server-items-length": _vm.totalTitulos,
              "loading-text": "Pesquisando títulos...",
              "no-data-text": "Preencha os filtros e clique em filtrar...",
              "no-results-text": "Nenhum título encontrado..."
            },
            on: {
              "update:options": function($event) {
                _vm.options = $event
              }
            },
            scopedSlots: _vm._u(
              [
                {
                  key: `item.id_titulo`,
                  fn: function({ item }) {
                    return [
                      item.ds_revisao != ""
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(item.id_titulo))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(item.id_titulo))
                          ])
                    ]
                  }
                },
                {
                  key: `item.ds_nosso_numero`,
                  fn: function({ item }) {
                    return [
                      item.ds_revisao != ""
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(item.ds_nosso_numero))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(item.ds_nosso_numero))
                          ])
                    ]
                  }
                },
                {
                  key: `item.dt_cadastro`,
                  fn: function({ item }) {
                    return [
                      item.ds_revisao != ""
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(item.dt_cadastro))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(item.dt_cadastro))
                          ])
                    ]
                  }
                },
                {
                  key: `item.dt_vencimento`,
                  fn: function({ item }) {
                    return [
                      item.ds_revisao != ""
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(_vm.formatDataVencimento(item)))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(_vm.formatDataVencimento(item)))
                          ])
                    ]
                  }
                },
                {
                  key: `item.nu_identificacao_devedor`,
                  fn: function({ item }) {
                    return [
                      item.ds_revisao != ""
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(_vm.formatDocumento(item)))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(_vm.formatDocumento(item)))
                          ])
                    ]
                  }
                },
                {
                  key: `item.nm_devedor`,
                  fn: function({ item }) {
                    return [
                      item.ds_revisao != ""
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(item.nm_devedor))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(item.nm_devedor))
                          ])
                    ]
                  }
                },
                {
                  key: `item.vl_saldo_protesto`,
                  fn: function({ item }) {
                    return [
                      item.ds_revisao != ""
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(item.vl_saldo_protesto))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(item.vl_saldo_protesto))
                          ])
                    ]
                  }
                },
                {
                  key: `item.cd_status`,
                  fn: function({ item }) {
                    return [
                      item.ds_revisao != ""
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(_vm.getDescSituacao(item)))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(_vm.getDescSituacao(item)))
                          ])
                    ]
                  }
                },
                {
                  key: `item.dt_atualizacao`,
                  fn: function({ item }) {
                    return [
                      item.ds_revisao != ""
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(item.dt_atualizacao))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(item.dt_atualizacao))
                          ])
                    ]
                  }
                },
                {
                  key: `item.cd_uf_devedor`,
                  fn: function({ item }) {
                    return [
                      item.ds_revisao != ""
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(item.cd_uf_devedor))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(item.cd_uf_devedor))
                          ])
                    ]
                  }
                },
                {
                  key: `item.cd_especie_titulo`,
                  fn: function({ item }) {
                    return [
                      item.ds_revisao != ""
                        ? _c(
                            "span",
                            {
                              style: { color: "red" },
                              attrs: {
                                title: "Este título precisa ser revisado. "
                              }
                            },
                            [_vm._v(_vm._s(item.cd_especie_titulo))]
                          )
                        : _c("span", { style: { color: "black" } }, [
                            _vm._v(_vm._s(item.cd_especie_titulo))
                          ])
                    ]
                  }
                }
              ],
              null,
              true
            ),
            model: {
              value: _vm.selecionados,
              callback: function($$v) {
                _vm.selecionados = $$v
              },
              expression: "selecionados"
            }
          }),
          _c(
            "v-card",
            { staticClass: "mt-3" },
            [
              _c(
                "v-row",
                { staticClass: "ma-0" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-col",
                    {
                      staticClass: "d-flex justify-end pr-9",
                      attrs: { sm: "6", md: "3", lg: "4" }
                    },
                    [
                      _c(
                        "v-tooltip",
                        {
                          attrs: {
                            top: "",
                            disabled: _vm.selecionados.length !== 0
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function({ on }) {
                                return [
                                  _c(
                                    "div",
                                    _vm._g({}, on),
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          directives: [
                                            {
                                              name: "intersect",
                                              rawName: "v-intersect",
                                              value: _vm.onIntersect,
                                              expression: "onIntersect"
                                            }
                                          ],
                                          staticClass: "d-flex justify-center",
                                          attrs: {
                                            color: "green white--text",
                                            disabled: !_vm.selecionados.length
                                          },
                                          on: { click: _vm.dialogTemCerteza }
                                        },
                                        [
                                          _vm._v(" Enviar Arquivos "),
                                          _c(
                                            "v-icon",
                                            { staticClass: "ml-2" },
                                            [_vm._v("mdi-chevron-right")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c("span", [
                            _vm._v(
                              "Selecione um ou mais títulos para liberar o comando"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.dialogNaoPermitido
            ? _c(
                "v-dialog",
                {
                  attrs: {
                    "max-width": "800px",
                    persistent: "",
                    scrollable: "",
                    color: "background",
                    transition: "dialog-bottom-transition"
                  },
                  model: {
                    value: _vm.dialogNaoPermitido,
                    callback: function($$v) {
                      _vm.dialogNaoPermitido = $$v
                    },
                    expression: "dialogNaoPermitido"
                  }
                },
                [
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-card-title",
                        [
                          _c(
                            "v-icon",
                            { staticClass: "ma-2", attrs: { large: "" } },
                            [_vm._v(" mdi-information ")]
                          ),
                          _vm._v("Ação não permitida. ")
                        ],
                        1
                      ),
                      _c("v-card-text", [
                        _vm._v(
                          " Para realizar ações de Upload de Arquivos de Protesto em Lote, "
                        ),
                        _c("strong", [
                          _vm._v("entre em contato com nosso suporte")
                        ]),
                        _vm._v(" através do email: "),
                        _c(
                          "a",
                          { attrs: { href: "mailto:suporte@recuperi.com.br" } },
                          [_vm._v("suporte@recuperi.com.br")]
                        ),
                        _vm._v(", nossa equipe irá auxiliar nessa ação. ")
                      ]),
                      _c(
                        "v-card-actions",
                        { staticClass: "d-flex justify-end my-3" },
                        [
                          _c(
                            "v-btn",
                            {
                              staticClass:
                                "white--text d-flex justify-center pl-5",
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.dialogNaoPermitido = false
                                }
                              }
                            },
                            [
                              _vm._v(" Fechar "),
                              _c("v-icon", { attrs: { large: "" } }, [
                                _vm._v(" mdi-chevron-right ")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }